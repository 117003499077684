.pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 20px;
  }
  
  .pagination .page-item {
    margin: 0 5px;
  }
  
  .pagination .page-link {
    color: #333;
    background-color: #fff;
    border: 1px solid #bbb;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .pagination .page-link:hover {
    background-color: #fff;
  }
  
  .pagination .active .page-link {
    background-color: #1E3A8A;
    color: #fff;
  }
  